import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import ContextConsumer from "src/layouts/Context.js"
import SEO from "src/components/global/SEO.js"
import distances from "src/assets/styles/distances.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import NavCompetence from "src/components/whatWeDo/NavCompetence.js"
import NavProjects from "src/components/whatWeDo/NavProjects.js"
import ProjectCard from "src/components/whatWeDo/ProjectCard.js"
import Wrapper from "src/components/global/Wrapper"
// import Crop from "src/components/global/Crop"
import ReactMarkdown from "react-markdown"
import CompetencesList from "src/components/global/competences/CompetencesList.js"

import BigText from "src/components/global/typography/BigText.js"

import MainBtn from "src/components/global/btn/MainBtn"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import MidText from "src/components/global/typography/MidText"
import BodyText from "src/components/global/typography/BodyText.js"

import { useLocation } from "react-router-dom"

const CompetenceWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
  }
`

const CompetenceContentWrapper = styled.div`
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const MainTitle = styled(BigText)`
  margin-bottom: 40px;
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
    width: 100%;
  }
`

const SubTitle = styled(BodyText)`
  margin-left: 50%;
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
  }
`

const CompetenceFirstP = styled(MidText)`
  margin-bottom: ${distances.mainBigMargin}px;
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
`

const CompetenceSecondP = styled(BodyText)`
  width: 77.77%;
  .marginP {
    p {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const Mobile100vh = styled.div`
  min-height: calc(100vh - 200px);
`

const WhatWeDo = ({ data, pageContext, location }) => {
  // const [num, setNum] = useQueryParam("index", NumberParam)
  const newData = data
  // let UrlIndex = 100000
  const [UrlIndex, setNum] = useState(100000)
  // const [num, setNum] = useQueryParam("x", NumberParam)
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (location.hash.split("=")[1]) {
        setNum(location.hash.split("=")[1])
      }
    }
  }, [])

  return (
    <>
      <SEO
        title={newData.strapiWhatWeDoPage.SEO?.SEO_Title}
        description={newData.strapiWhatWeDoPage.SEO?.SEO_Description}
        image={newData.strapiWhatWeDoPage.SEO.Seo_Img.publicURL}
      />

      <Navigation
        lang={pageContext.locale}
        langUrl={pageContext.langUrl}
        navBtnLink={data.strapiGlobal.Nav_btn_link}
        navBtnText={data.strapiGlobal.Nav_btn_text}
      />
      <Mobile100vh>
        <CompetenceWrapper id="CompetenceWrapper">
          <MainTitle>{noHangers(newData.strapiWhatWeDoPage.Title)}</MainTitle>
          <SubTitle>{noHangers(newData.strapiWhatWeDoPage.Subtitle)} </SubTitle>
          <CompetencesList
            lang={pageContext.locale}
            competences={newData.allStrapiCompetences.edges}
          />
        </CompetenceWrapper>
      </Mobile100vh>

      <Footer lang={pageContext.locale} companyData={data.strapiContactPageN} />
    </>
  )
  // }
}

export const query = graphql`
  query WhatWeDo($locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      Nav_btn_link
      Nav_btn_text
    }
    strapiWhatWeDoPage(locale: { eq: $locale }) {
      Title
      Subtitle
      SEO {
        SEO_Title
        SEO_Description
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiCompetences(
      filter: { locale: { eq: $locale } }
      sort: { order: ASC, fields: Order }
    ) {
      edges {
        node {
          Name
          Order
          strapiId
          Title
          First_paragraph
          Second_paragraph
          Btn {
            Hover
            Main
            Mobile
          }
        }
      }
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default WhatWeDo
